import React from 'react'
import Layout from '../components/layout'

class Links extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Links | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Links</h2>
              </header>
              <div>
                <span>
                  Ich bin Mitglied in folgenden Berufs- und Fachverbänden bzw.
                  kollegialen Netzwerken:
                </span>
                <ul>
                  <li>
                    <a
                      href="https://www.ptv-nrw.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Psychotherapeutenkammer NRW
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.dptv.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Deutsche Psychotherapeuten Vereinigung
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.ptn-muenster.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PsychotherapeutInnenNetzwerk Münster und Münsterland e.V.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.dgvt.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Deutsche Gesellschaft für Verhaltenstherapie e.V.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.kvwl.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kassenärztliche Vereinigung Westfalen Lippe
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tfbs.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Behrenberg + Faßnacht – Training. Fortbildung. Beratung.
                      Supervision.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.igo-muenster.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Institut für Gruppendynamik und Organisationsberatung
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.dggo.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Deutsche Gesellschaft für Gruppendynamik und
                      Organisationsdynamik e.V.
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.dgsv.de/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Deutsche Gesellschaft für Supervision e.V.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Links
